import { blockchainHandler, WaggleForageOrder } from '@/blockchainHandlers'
import { MarketKeyInfo } from '@/blockchainHandlers/ido-contract-interface'
import { SolidityIdoContract } from '@/blockchainHandlers/ido-contract-solidity'
import { WaggleForageInterface } from '@/blockchainHandlers/waggle-forage-interface'
import { WaggleForageContract } from '@/blockchainHandlers/waggle-forage-solidity'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { FIXEDNUMBER_100, Zero } from '@/constants'
import { strEquals } from '@/helpers'
import { bigNumberHelper, bnHelper } from '@/helpers/bignumber-helper'
import { numberHelper } from '@/helpers/number.hepler'
import { promiseHelper } from '@/helpers/promise-helper'
import { WaggleNftKeyCardModel } from '@/modules/dashboard/viewmodels/dashboard-viewmodel'
import { formatDuration } from '@/modules/ido/business/swap-contract.business'
import { PoolStore } from '@/modules/ido/stores/pool-store'
import { poolsStore } from '@/modules/ido/stores/pools-store'
import { apiService } from '@/services/api-service'
import { walletStore } from '@/stores/wallet-store'
// import { PoolStore } from '../stores/pool-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { WalletSignTransactionError } from '@solana/wallet-adapter-base'
import { ceil, flatten, orderBy, round } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import web3 from 'web3'
import { ForageDialogResult } from './market-item-detail-viewmodel'

class NFTSaleViewModel {
  @observable walletAddresses: string[] = []
  @observable selectedNFT: WaggleNftKeyCardModel | null = null
  @observable loading = false
  @observable searchKey = ''
  @observable ratioInput = ''
  @observable salePriceInput = ''

  @observable myNftPage = 1
  @observable myNftItemsPerPage = 10

  @observable orderCreating = false

  @observable approving = false
  @observable approvedNft = false
  @observable sellerTax = Zero
  @observable buyerTax = Zero

  waggleForageContract: WaggleForageInterface | null = null

  constructor() {
    //
  }

  @asyncAction *loadData(account) {
    const card = this.selectedNFT
    if (!account || !card) return
    const contract = blockchainHandler.getWaggleForageContract(card.poolStore.chainId)
    if (!contract) {
      console.log('contract is undefined')
      return
    }
    this.waggleForageContract = contract
    this.sellerTax = contract.sellerTax
    this.buyerTax = contract.buyerTax
    yield this.waggleForageContract?.init()
    if (card.poolStore.chain === 'sol') {
      this.approvedNft = true
    } else {
      const solContract = contract as WaggleForageContract
      const approved = yield solContract.marketNftKey.isApprovedAll(account, solContract.address)
      this.approvedNft = approved
    }
  }

  @asyncAction *approveNft() {
    this.approving = true
    try {
      const contract = this.waggleForageContract as WaggleForageContract
      yield contract.marketNftKey.aprroveAll(walletStore.account, contract.address)
      this.approvedNft = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.approving = false
    }
  }

  @action resetInput() {
    this.salePriceInput = ''
    this.ratioInput = ''
  }

  @asyncAction *createOrder() {
    this.orderCreating = true
    try {
      if (this.selectedNFT?.poolStore?.pool?.data?.disableTransfer) {
        throw 'This NFT cannot be sold or transferred'
      }
      const contract = this.waggleForageContract
      if (!contract) throw 'Contract is undefined'
      if (!this.selectedNFT) throw 'selectedNFT is undefined'
      yield contract.createOrder(
        walletStore.account,
        this.selectedNFT.keyInfo.id,
        FixedNumber.from(this.salePriceInput)
      )
      this.changeRatioInput('0')
      try {
        marketViewModel.fetchOpenOrders()

        yield this.selectedNFT.poolStore?.contract?.fetchUserMarketKeyInfos(walletStore.account, true)
      } catch (e: any) {
        console.log(e.message || e.msg)
      }
      snackController.success('Order created')
      this.selectedNFT = null
      return 'success'
    } catch (error) {
      snackController.commonError(error)
      return isCancelSolTransaction(this.selectedNFT?.poolStore, error)
    } finally {
      this.orderCreating = false
    }
  }

  @action.bound changeSearchKey(val) {
    this.searchKey = val
  }

  @action.bound selectNftCard(val) {
    this.selectedNFT = val
    this.loadData(walletStore.account)
  }

  @computed get myNftCards() {
    return marketViewModel.myNftCards.filter((item) => !item?.poolStore?.pool?.data?.disableTransfer)
  }

  @computed get slicedMyNfts() {
    return this.myNftCards.slice((this.myNftPage - 1) * this.myNftItemsPerPage, this.myNftPage * this.myNftItemsPerPage)
  }
  @computed get myNftPageLength() {
    return ceil(this.myNftCards.length / this.myNftItemsPerPage)
  }

  @action.bound changeRatioInput(val) {
    if (val && numberHelper.isFloatNumber(val)) {
      this.ratioInput = val
      this.salePriceInput = FixedNumber.from(this.ratioInput + '')
        .mulUnsafe(this.remainAmount)
        .toString()
      this.salePriceInput = (+this.salePriceInput).toString()
    } else {
      this.salePriceInput = ''
    }
  }
  @action.bound changeSalePrice(val) {
    if (val && numberHelper.isFloatNumber(val)) {
      this.salePriceInput = val
      this.ratioInput = FixedNumber.from(this.salePriceInput + '')
        .divUnsafe(this.remainAmount)
        .toString()
      this.ratioInput = (+this.ratioInput).toString()
    } else {
      this.ratioInput = ''
    }
  }

  @computed get isValidRatioInput() {
    if (
      !this.ratioInput ||
      !numberHelper.isFloatNumber(this.ratioInput) ||
      FixedNumber.from(this.ratioInput).isNegative() ||
      FixedNumber.from(this.ratioInput).isZero()
    )
      return false
    return true
  }

  @computed get requiredChain() {
    return this.selectedNFT ? this.selectedNFT.poolStore?.chain : 'bsc'
  }
  @computed get requiredChainId() {
    return this.selectedNFT ? this.selectedNFT.poolStore?.chainId : 56
  }

  @computed get tokenName() {
    return this.selectedNFT ? this.selectedNFT.poolStore.tokenName : ''
  }
  @computed get tradeToken() {
    return this.selectedNFT ? this.selectedNFT.poolStore.tradeToken : ''
  }
  @computed get tradeValue() {
    return this.selectedNFT ? this.selectedNFT.poolStore.tradeValue : FixedNumber.from('0')
  }
  @computed get remainAmount() {
    return this.selectedNFT ? this.selectedNFT.keyInfo.remain : FixedNumber.from('0')
  }
  @computed get marketPrice() {
    return this.selectedNFT ? this.selectedNFT.poolStore.marketPrice : FixedNumber.from('0')
  }
  @computed get sellerTaxPrice() {
    if (!this.isValidSalePriceInput || this.sellerTax.isZero()) return Zero
    return FixedNumber.from(this.salePriceInput).mulUnsafe(this.sellerTax).divUnsafe(FIXEDNUMBER_100)
  }

  @computed get isValidSalePriceInput() {
    return !(!numberHelper.isFloatNumber(this.salePriceInput) || +this.salePriceInput <= 0)
  }

  @computed get recievedAmount() {
    return this.isValidSalePriceInput ? FixedNumber.from(this.salePriceInput).subUnsafe(this.sellerTaxPrice) : Zero
  }
}

export interface MarketStatistics {
  startDate: Date
  endDate: Date
  nftSold: number
  chainId: number
  volume: FixedNumber
  status: string
  data: any
}

export interface MarketTransaction {
  chainId: number
  nftId: number
  timestamp: Date
  buyerAddress: string
  buyerUser: any
  sellerUser: any
  salePrice: FixedNumber
  transactionHash: string
  sellerAddress: string
  type: string
  status: string
  data: any
  metadata: any
  nftInfo: any
  poolStore: any
}

export class MarketViewModel {
  @observable loadingPage = false

  @observable sellingNFTs: MarketItemViewModel[] = []

  //market statistics
  @observable marketStatistics: MarketStatistics | null = null
  @observable marketTransactions: MarketTransaction[] = []
  @observable totalVolume: FixedNumber = FixedNumber.from('0')
  @observable totalSold = 0
  @observable loadingMarketInfos = true
  @observable loadingMarketTransactions = true

  //my nfts
  @observable myNftCards: WaggleNftKeyCardModel[] = []
  @observable loadingMyCards = false

  @observable walletAddresses: string[] = []
  @observable approved = false
  @observable approving = false
  @observable buying = false
  @observable loading = false
  @observable showMySaleOnly = false
  @observable selectedItem: MarketItemViewModel | null = null
  @observable claimingOrder = false

  //filters
  @observable itemsPerPage = 8
  @observable page = 1
  //solana section default 101
  @observable chainId = 103
  @observable searchKey = ''
  @observable searchNftId = ''
  @observable searchDuration = ''
  @observable searchPriceMin = ''
  @observable searchPriceMax = ''
  @observable sortField = 'time-asc'
  @observable isMyNfts = false
  @observable sellerTax = Zero
  @observable buyerTax = Zero
  @observable offerRatioInput = ''
  @observable offerPriceInput = ''
  @observable makeOfferError = ''

  @observable tradeTokenBalance = FixedNumber.from('0')

  waggleForageContract: WaggleForageInterface | null = null

  constructor() {
    reaction(
      () => walletStore.account,
      () => {
        this.fetchMyCards()
        this.fetchTradeTokenBalance()
        this.fetchMyMarketInfos()
      },
      { fireImmediately: true }
    )
    reaction(
      () => this.chainId,
      () => {
        this._loadData()
      },
      { fireImmediately: true }
    )
    reaction(
      () => this.pageLength,
      () => {
        if (this.page > this.pageLength || this.page === 0) {
          this.page = this.pageLength
        }
      }
    )
  }

  async _loadData() {
    this.loadingPage = true
    const contract = blockchainHandler.getWaggleForageContract(this.chainId)
    if (!contract) throw 'Contract is undefined'
    this.waggleForageContract = contract
    await contract.init()
    this.sellerTax = (this.waggleForageContract as any).sellerTax
    this.buyerTax = (this.waggleForageContract as any).buyerTax
    this.fetchStatisticInfos()
    this.fetchTradeTokenBalance()
    this.fetchOpenOrders()
    this.loadingPage = false
  }

  async fetchStatisticInfos() {
    this.fetchMarketStatistics()
    this.fetchMarketTransactions()
    this.fetchMyMarketInfos()
  }

  @action.bound changeSelectedItem(val) {
    this.selectedItem = val
  }

  @asyncAction *approveUsd() {
    const contract = this.waggleForageContract as WaggleForageContract
    this.approving = true
    try {
      if (!contract) throw 'approveUsd: contract is undefined'
      yield contract.approve(walletStore.account)
      this.approved = true
      snackController.success('Approved')
    } catch (error) {
      snackController.blockchainError(error)
    } finally {
      this.approving = false
    }
  }

  @asyncAction *claimOrder() {
    const item = this.selectedItem
    const contract = this.waggleForageContract
    if (!item || !contract || !item.order) throw 'order is undefined'
    this.claimingOrder = true
    try {
      yield contract.claimOrder(walletStore.account, item.order)
      // snackController.success(`Approve offer for NFT #${item.keyId} successfully!`)
      yield this.fetchTradeTokenBalance()
      try {
        this.fetchMyCards()
        yield item.poolStore.contract?.fetchUserMarketKeyInfos(walletStore.account, true)
      } catch {
        //
      }
      return 'success'
    } catch (error) {
      snackController.blockchainError(error)
      return isCancelSolTransaction(item?.poolStore, error)
    } finally {
      this.fetchOpenOrders()
      this.claimingOrder = false
    }
  }

  @asyncAction *buy(isMakeOffer = false) {
    const item = this.selectedItem
    const contract = this.waggleForageContract
    if (!item || !contract) throw 'item || contract is undefined'
    if (isMakeOffer && !this.isValidOfferPriceInput) throw 'Offer price input is invalid'
    this.buying = true
    try {
      yield contract.buy(
        walletStore.account,
        item.order,
        isMakeOffer ? FixedNumber.from(this.offerPriceInput) : item.price
      )
      snackController.success(`${isMakeOffer ? 'Make offer for' : 'Buy'} NFT #${item.keyId} successfully!`)
      setTimeout(() => this.fetchStatisticInfos(), 10000)
      yield this.fetchTradeTokenBalance()
      try {
        this.fetchMyCards()
        yield item.poolStore.contract?.fetchUserMarketKeyInfos(walletStore.account, true)
      } catch {
        //
      }
      return 'success'
    } catch (error) {
      snackController.blockchainError(error)
      return isCancelSolTransaction(item?.poolStore, error)
    } finally {
      this.fetchOpenOrders()
      this.buying = false
    }
  }

  @asyncAction *cancelOrder(item: MarketItemViewModel) {
    const contract = this.waggleForageContract
    if (!contract) throw 'Contract is undefined'

    try {
      item.changeCancel(true)
      yield contract.cancelOrder(walletStore.account, item.order)
      try {
        yield item.poolStore.contract?.fetchUserMarketKeyInfos(walletStore.account, true)
      } catch {
        //
      }
      snackController.success(`Cancel order successfully`)
      this.fetchOpenOrders()
      this.fetchMyCards()
      return true
    } catch (error) {
      snackController.blockchainError(error)
      return false
    } finally {
      item.changeCancel(false)
    }
  }
  @asyncAction *cancelOffer(item: MarketItemViewModel) {
    const contract = this.waggleForageContract
    if (!contract) throw 'Contract is undefined'
    try {
      item.changeCancelOffer(true)
      yield contract.cancelOffer(walletStore.account, item.order)
      try {
        yield item.poolStore.contract?.fetchUserMarketKeyInfos(walletStore.account, true)
      } catch {
        //
      }
      snackController.success(`Cancel offer successfully`)
      this.fetchOpenOrders()
      this.fetchMyCards()
      return true
    } catch (error) {
      snackController.blockchainError(error)
      return false
    } finally {
      item.changeCancelOffer(false)
    }
  }

  compareSalePrice(a: MarketItemViewModel, b: MarketItemViewModel) {
    if (bigNumberHelper.lt(a.price, b.price)) {
      return -1
    }
    if (bigNumberHelper.gt(a.price, b.price)) {
      return 1
    }
    return 0
  }
  compareTokenPrice(a: MarketItemViewModel, b: MarketItemViewModel) {
    if (bigNumberHelper.lt(a.pricePerToken, b.pricePerToken)) {
      return -1
    }
    if (bigNumberHelper.gt(a.pricePerToken, b.pricePerToken)) {
      return 1
    }
    return 0
  }

  @asyncAction *fetchMyMarketInfos() {
    const contract = this.waggleForageContract
    const account = walletStore.account
    if (!contract || !account || walletStore.chainId !== this.chainId) return
    if (walletStore.chainType === 'sol') {
      this.approved = true
    } else {
      const solContract = contract as WaggleForageContract
      this.approved = yield solContract.isApproved(walletStore.account)
      yield promiseHelper.delay(1)
    }
  }

  @asyncAction *fetchMarketTransactions() {
    this.loadingMarketTransactions = true
    try {
      this.marketTransactions = yield apiService.transactions.find(
        { chainId: this.chainId },
        { _limit: 10, _sort: 'timestamp:desc' }
      )
      yield poolsStore.fetchPoolsIfNeed()
      for (let i = 0; i < this.marketTransactions.length; i++) {
        const transaction = this.marketTransactions[i]
        if (!transaction.nftId || transaction.nftId === 0) continue
        const nftInfo = yield this.fetchNftInfo(transaction.nftId)
        if (!nftInfo || !nftInfo.market) continue
        transaction.nftInfo = nftInfo
        transaction.poolStore = poolsStore.allPools.find((p) => strEquals(p.pool.address, nftInfo.market))
        transaction.status = 'done'
      }
    } catch (error) {
      snackController.error(error as any)
    } finally {
      this.loadingMarketTransactions = false
    }
  }

  @asyncAction *fetchNftInfo(keyId) {
    if (!this.waggleForageContract) throw 'waggleForageContract is undefined'
    return yield this.waggleForageContract.getNftInfo(keyId)
  }

  @asyncAction *fetchMarketStatistics() {
    this.loadingMarketInfos = true
    try {
      const currentMarketStatistics = yield apiService.marketStatistics.find(
        {
          startDate_lte: moment().toISOString(),
          endDate_gte: moment().toISOString(),
          chainId: +this.chainId,
        },
        { _limit: 1 }
      )
      this.marketStatistics = currentMarketStatistics[0]
      const infos = yield apiService.getMarketInfo(this.chainId)
      this.totalSold = infos && infos.totalSold ? infos.totalSold : 0
      this.totalVolume = infos && infos.totalVolume ? FixedNumber.from(infos.totalVolume) : FixedNumber.from('0')
    } catch (err) {
      console.log(err)
    } finally {
      this.loadingMarketInfos = false
    }
  }

  @computed get fixedTotalVolume() {
    return this.totalVolume.mulUnsafe(FixedNumber.from('3'))
  }

  @asyncAction *fetchTradeTokenBalance() {
    const contract = this.waggleForageContract
    const account = walletStore.account
    if (!contract || !account || walletStore.chainId !== this.chainId) return
    try {
      this.tradeTokenBalance = yield contract.getUserTradeTokenAmount(walletStore.account)
    } catch (e: any) {
      console.log('tradeTokenBalance error', e.msg || e.message)
      this.tradeTokenBalance = Zero
    }
  }

  @asyncAction *fetchOpenOrders() {
    this.loading = true
    const contract = this.waggleForageContract
    if (!contract) return
    yield contract.init()
    const openOrders: WaggleForageOrder[] = yield contract.getAllOpenOrders()
    const groupKeyByIdos = openOrders.reduce((prev, cur) => {
      if (!prev[cur.market]) {
        prev[cur.market] = []
      }
      prev[cur.market].push(cur.id)
      return prev
    }, {} as { [id: number]: string[] })
    yield poolsStore.fetchPoolsIfNeed()

    const keyInfos: { [keyId: number]: MarketKeyInfo } = {}
    yield Promise.all(
      Object.entries(groupKeyByIdos).map(async ([idoAddress, keyIds]) => {
        const poolStore = poolsStore.allPools.find((p) => strEquals(p.pool.address, idoAddress))
        if (poolStore) {
          await poolStore.loadData()
          const contract = poolStore.contract as SolidityIdoContract
          const results = await contract.fetchMarketKeyInfos(keyIds)
          for (const keyInfo of results) {
            keyInfos[keyInfo.id] = keyInfo
          }
        }
      })
    )

    const result =
      openOrders
        .filter((o) => keyInfos[o.id])
        .map((o) => {
          return new MarketItemViewModel(
            this.sellerTax,
            this.buyerTax,
            o,
            keyInfos[o.id],
            poolsStore.allPools.find((p) => strEquals(p.pool.address, o.market)) as any
          )
        }) || []

    this.sellingNFTs = result.filter((item) => !item.poolStore.disableTransfer)
    this.loading = false
  }

  @asyncAction *fetchMyCards() {
    const wallet = walletStore.account
    if (!wallet) {
      this.myNftCards = []
      return
    }
    try {
      if (this.loadingMyCards) return
      this.loadingMyCards = true
      const projects: PoolStore[] = (yield poolsStore.fetchPoolsIfNeed()) as any
      const results: WaggleNftKeyCardModel[][] = yield Promise.all(
        projects
          .filter((p) => {
            return walletStore.chainType === p.chain && walletStore.chainId === p.chainId
          })
          .map(async (poolStore) => {
            try {
              const contract = poolStore.contract
              if (contract) {
                await poolStore.loadData()

                const isSolidityWallet = web3.utils.isAddress(wallet)
                let results: WaggleNftKeyCardModel[] = []
                if (isSolidityWallet) {
                  if (poolStore.chain !== 'sol') {
                    const keyInfos = await contract.fetchUserMarketKeyInfos(wallet)
                    results = keyInfos.map((keyInfo) => ({
                      wallet,
                      keyInfo,
                      poolStore,
                    }))
                  }
                } else {
                  if (poolStore.chain === 'sol') {
                    const keyInfos = await contract.fetchUserMarketKeyInfos(wallet)
                    results = keyInfos.map((keyInfo) => ({
                      wallet,
                      keyInfo,
                      poolStore,
                    }))
                  }
                }
                return results
              }
            } catch (error) {
              console.error(error)
            }
            return []
          })
      )
      this.myNftCards = flatten(results)
    } catch (err) {
      console.error(err)
      snackController.error('There is error, please try again')
    } finally {
      this.loadingMyCards = false
    }
  }

  @action.bound clearFilters() {
    this.searchKey = ''
    this.searchNftId = ''
    this.searchDuration = ''
    this.searchPriceMin = ''
    this.searchPriceMax = ''
    this.sortField = ''
  }
  @action.bound toggleIsMyNfts() {
    this.isMyNfts = !this.isMyNfts
  }
  @action.bound changeSearchNetwork(val) {
    this.chainId = val
    this.resetStatisticInfo()
    this.fetchMarketStatistics()
  }

  @action resetStatisticInfo() {
    this.totalSold = 0
    this.totalVolume = FixedNumber.from('0')
    this.marketStatistics = null
  }
  @action.bound changeShowMySaleOnly(val) {
    this.showMySaleOnly = val
  }
  @action.bound changeSearchKey(val) {
    this.searchKey = val
  }
  @action.bound changeSearchNftId(val) {
    this.searchNftId = val
  }
  @action.bound changeSearchDuration(val) {
    this.searchDuration = val
  }
  @action.bound changeSearchPriceMin(val) {
    this.searchPriceMin = val
  }
  @action.bound changeSearchPriceMax(val) {
    this.searchPriceMax = val
  }
  @action.bound changeSortField(val) {
    this.sortField = val
  }

  @action resetOfferInput() {
    this.offerRatioInput = ''
    this.offerPriceInput = ''
    this.makeOfferError = ''
  }

  @action.bound offerRatioInputOnChange(input) {
    this.makeOfferError = ''
    if (input && numberHelper.isFloatNumber(input)) {
      this.offerRatioInput = input
      this.offerPriceInput = this.remainToken.mulUnsafe(FixedNumber.from(`${input}`)).toString()
      this.offerPriceInput = (+this.offerPriceInput).toString()
    } else {
      this.offerPriceInput = ''
    }
  }
  @action.bound offerPriceInputOnChange(input) {
    this.makeOfferError = ''
    if (input && numberHelper.isFloatNumber(input) && !this.remainToken.isZero()) {
      this.offerPriceInput = input
      this.offerRatioInput = FixedNumber.from(`${input}`).divUnsafe(this.remainToken).toString()
      this.offerRatioInput = (+this.offerRatioInput).toString()
    } else {
      this.offerRatioInput = ''
    }
  }

  @action checkOfferInput() {
    if (!this.isValidOfferPriceInput) {
      this.makeOfferError = 'Your offer must be higher than the current offer'
      return false
    }
    if (!bnHelper.gte(this.tradeTokenBalance, this.buyerTotalPay)) {
      this.makeOfferError = 'Balance is not sufficient'
      return false
    }
    return true
  }

  @computed get sellerTaxPrice() {
    if (!this.buyerBidPrice || this.buyerBidPrice.isZero()) return Zero
    return this.sellerTax.mulUnsafe(this.buyerBidPrice).divUnsafe(FIXEDNUMBER_100)
  }

  @computed get buyerTaxPrice() {
    if (!this.buyerBidPrice || this.buyerBidPrice.isZero()) return Zero
    return this.buyerTax.mulUnsafe(this.buyerBidPrice).divUnsafe(FIXEDNUMBER_100)
  }
  @computed get buyerBidPrice() {
    return this.selectedItem?.buyerBidPrice || Zero
  }
  @computed get remainToken() {
    return this.selectedItem?.remainToken || Zero
  }
  @computed get sellerRecievedUsd() {
    return this.buyerBidPrice.subUnsafe(this.sellerTaxPrice)
  }
  @computed get offerPricePerToken() {
    if (!this.remainToken || this.remainToken.isZero()) return Zero
    return this.buyerBidPrice.divUnsafe(this.remainToken)
  }

  @computed get isNotOffer() {
    return this.buyerBidPrice.isZero()
  }
  @computed get buyer() {
    return this.selectedItem?.order?.buyer || ''
  }
  @computed get seller() {
    return this.selectedItem?.order?.seller || ''
  }

  @computed get isValidOfferPriceInput() {
    if (!numberHelper.isFloatNumber(this.offerPriceInput) || +this.offerPriceInput <= 0) return false
    return bnHelper.gt(FixedNumber.from(this.offerPriceInput), this.buyerBidPrice)
  }

  @computed get offerBuyerTaxPrice() {
    return this.isValidOfferPriceInput
      ? FixedNumber.from(this.offerPriceInput).mulUnsafe(this.buyerTax).divUnsafe(FIXEDNUMBER_100)
      : Zero
  }

  @computed get buyerTotalPay() {
    return this.isValidOfferPriceInput
      ? this.offerBuyerTaxPrice.addUnsafe(FixedNumber.from(this.offerPriceInput))
      : Zero
  }

  @computed get filteredSellingNfts() {
    // const nfts = this.isMyNfts ? this.mySaleNfts : this.sellingNFTs
    const keyword = this.searchKey.toLowerCase()
    // return nfts.filter((nft) => {
    return this.sellingNFTs.filter((nft) => {
      if (this.showMySaleOnly && !strEquals(nft.order.seller, walletStore.account)) {
        return false
      }
      if (
        this.searchKey &&
        !nft.poolStore?.tokenName?.toLowerCase()?.includes(keyword) &&
        !nft.poolStore?.tokenAddress?.toLowerCase()?.includes(keyword) &&
        !nft.poolStore?.projectName?.toLowerCase()?.includes(keyword)
      )
        return false
      if (nft.poolStore?.chainId !== this.chainId) return false
      if (this.searchNftId && !nft.keyInfo?.id.toString().includes(this.searchNftId)) return false
      if (this.searchDuration && nft.poolStore) {
        if (
          this.searchDuration === 'short' &&
          (nft.poolStore.vestingDurationInMonths > 6 || nft.poolStore.vestingDurationInMonths < 0)
        ) {
          return false
        }
        if (
          this.searchDuration === 'mid' &&
          (nft.poolStore.vestingDurationInMonths > 12 || nft.poolStore.vestingDurationInMonths <= 6)
        )
          return false
        if (this.searchDuration === 'long' && nft.poolStore.vestingDurationInMonths <= 12) return false
      }
      if (
        this.searchPriceMax &&
        nft.price &&
        (!this.isValidPriceMax ||
          (this.searchPriceMin && +this.searchPriceMin > +this.searchPriceMax) ||
          bigNumberHelper.gt(nft.price, FixedNumber.from(this.searchPriceMax)))
      )
        return false
      if (
        this.searchPriceMin &&
        nft.price &&
        (!this.isValidPriceMin || bigNumberHelper.lt(nft.price, FixedNumber.from(this.searchPriceMin)))
      )
        return false
      return true
    })
  }

  @computed get isValidPriceMax() {
    if (
      !this.searchPriceMax ||
      !numberHelper.isFloatNumber(this.searchPriceMax) ||
      FixedNumber.from(this.searchPriceMax).isNegative() ||
      FixedNumber.from(this.searchPriceMax).isZero()
    )
      return false
    return true
  }
  @computed get isValidPriceMin() {
    if (
      !this.searchPriceMin ||
      !numberHelper.isFloatNumber(this.searchPriceMin) ||
      FixedNumber.from(this.searchPriceMin).isNegative() ||
      FixedNumber.from(this.searchPriceMin).isZero()
    )
      return false
    return true
  }

  @computed get sortedSellingNfts() {
    const sorted = [...this.filteredSellingNfts]
    if (this.sortField === 'price-asc') sorted.sort((a, b) => this.compareSalePrice(a, b))
    else if (this.sortField === 'price-desc') sorted.sort((a, b) => this.compareSalePrice(b, a))
    else if (this.sortField === 'token-price-asc') sorted.sort((a, b) => this.compareTokenPrice(a, b))
    else if (this.sortField === 'token-price-desc') sorted.sort((a, b) => this.compareTokenPrice(b, a))
    else if (this.sortField === 'time-asc') return orderBy(sorted, ['createAt'], ['desc'])
    else if (this.sortField === 'time-desc') return orderBy(sorted, ['createAt'], ['asc'])
    return sorted
  }

  @computed get totalSellingNFTs() {
    return this.sortedSellingNfts.length || 0
  }

  @computed get slicedSellingNfts() {
    return this.sortedSellingNfts.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage)
  }

  @computed get mySaleNfts() {
    return this.sellingNFTs.filter((s) => s.isOwner)
  }

  @computed get filteredMarketTransactions() {
    const filteredTransactions = this.marketTransactions
      .filter(
        (transaction) =>
          transaction.nftId !== 0 &&
          transaction.chainId === this.chainId &&
          transaction.status == 'done' &&
          transaction.nftInfo &&
          transaction.poolStore
      )
      .slice(0, 10)
    return filteredTransactions
  }

  @computed get recentSellingNFTs() {
    const sortedSellingNFTs = orderBy(this.sellingNFTs, ['createAt'], ['desc'])
    return sortedSellingNFTs.slice(0, 10)
  }

  @computed get pageLength() {
    return ceil(this.sortedSellingNfts.length / this.itemsPerPage)
  }

  @computed get sortFields() {
    return [
      {
        title: 'Token Price Ascending',
        value: 'token-price-asc',
      },
      {
        title: 'Token Price Descending',
        value: 'token-price-desc',
      },
      {
        title: 'Sale Price Ascending',
        value: 'price-asc',
      },
      {
        title: 'Sale Price Descending',
        value: 'price-desc',
      },
      {
        title: 'Sale Time Ascending',
        value: 'time-asc',
      },
      {
        title: 'Sale Time Descending',
        value: 'time-desc',
      },
    ]
  }
}

export function isCancelSolTransaction(poolStore?: PoolStore, error?: any): ForageDialogResult {
  const chainId = poolStore?.chainId
  const isSolana = chainId && (+chainId === 101 || +chainId === 103)
  return isSolana && error instanceof WalletSignTransactionError && error?.message === 'Transaction cancelled'
    ? 'cancel-error'
    : 'error'
}

export class MarketItemViewModel {
  @observable trade2IdoToken = false
  _disposers: IReactionDisposer[] = []

  @observable canceling = false
  @observable cancelingOffer = false

  constructor(
    public sellerTax: FixedNumber,
    public buyerTax: FixedNumber,
    public order: WaggleForageOrder,
    public keyInfo: MarketKeyInfo,
    public poolStore: PoolStore
  ) {
    //
  }

  @action.bound changeCancel(val) {
    this.canceling = val
  }
  @action.bound changeCancelOffer(val) {
    this.cancelingOffer = val
  }

  @computed get createAt() {
    return moment(this.order.createdAt)
  }

  @computed get createdTime() {
    return formatDuration(moment.duration(moment().diff(this.order.createdAt))) + ' ago'
  }

  @computed get price() {
    return this.order.price || Zero
  }
  @computed get pool() {
    return this.poolStore.pool
  }
  @computed get nftId() {
    return this.keyInfo.id
  }
  @computed get marketPrice() {
    return this.poolStore.marketPrice
  }
  @computed get tokenName() {
    return this.poolStore?.tokenName || ''
  }
  @computed get tradeToken() {
    return this.poolStore?.tradeToken || ''
  }
  @computed get tradeValue() {
    return this.poolStore?.tradeValue || Zero
  }
  @computed get remainToken() {
    return this.keyInfo?.remain || Zero
  }
  @computed get pricePerToken() {
    return this.price.divUnsafe(this.remainToken)
  }
  @action.bound togglePriceShow() {
    this.trade2IdoToken = !this.trade2IdoToken
  }
  @computed get formattedRemainingToken() {
    if (!this.keyInfo) return FixedNumber.from('0')
    return numberHelper.formatNumber(this.keyInfo?.remain, 5, 0)
  }
  @computed get formattedPrice() {
    return this.price || '0'
  }
  @computed get priceText() {
    if (this.trade2IdoToken) {
      const val = round(FixedNumber.from('1').divUnsafe(this.pricePerToken).toUnsafeFloat(), 5)
      return `1 ${this.tradeToken} = ${val} ${this.tokenName || ''}`
    } else {
      const val = round(this.pricePerToken.toUnsafeFloat(), 5)
      return `1 ${this.tokenName} = ${val} ${this.tradeToken || ''}`
    }
  }
  @computed get tradeRatio() {
    return FixedNumber.from('1').divUnsafe(this.pricePerToken)
  }
  @computed get tradeTokenSufficient() {
    return bnHelper.gt(this.buyerTotalPay, marketViewModel.tradeTokenBalance)
  }
  @computed get isOwner() {
    return strEquals(this.order.seller, walletStore.account)
  }

  @computed get isCurrentOfferBuyer() {
    return this.order.buyer && strEquals(this.order.buyer, walletStore.account)
  }

  @computed get isValidMarketPrice() {
    return this.marketPrice && !this.marketPrice.isZero()
  }
  @computed get requiredChain() {
    return this.poolStore.chain
  }
  @computed get requiredChainId() {
    return this.poolStore.chainId
  }
  @computed get isNotOffer() {
    return this.buyerBidPrice.isZero()
  }
  @computed get buyerBidPrice() {
    return this.order?.buyerBidPrice || Zero
  }
  @computed get offerSellerTaxPrice() {
    if (!this.buyerBidPrice || this.buyerBidPrice.isZero()) return Zero
    return this.sellerTax.mulUnsafe(this.buyerBidPrice).divUnsafe(FIXEDNUMBER_100)
  }
  @computed get sellerTaxPrice() {
    if (!this.price || this.price.isZero()) return Zero
    return this.sellerTax.mulUnsafe(this.price).divUnsafe(FIXEDNUMBER_100)
  }
  @computed get offerBuyerTaxPrice() {
    if (!this.buyerBidPrice || this.buyerBidPrice.isZero()) return Zero
    return this.buyerTax.mulUnsafe(this.buyerBidPrice).divUnsafe(FIXEDNUMBER_100)
  }
  @computed get buyerTaxPrice() {
    if (!this.price || this.price.isZero()) return Zero
    return this.buyerTax.mulUnsafe(this.price).divUnsafe(FIXEDNUMBER_100)
  }
  @computed get sellerRecievedOfferUsd() {
    return this.buyerBidPrice.subUnsafe(this.offerSellerTaxPrice)
  }
  @computed get offerPricePerToken() {
    if (!this.remainToken || this.remainToken.isZero()) return
    return this.buyerBidPrice.divUnsafe(this.remainToken)
  }
  @computed get buyerTotalPay() {
    return this.price.addUnsafe(this.buyerTaxPrice)
  }
  @computed get keyId() {
    return this.keyInfo?.id || ''
  }
}

export const marketViewModel = new MarketViewModel()
export const saleViewModel = new NFTSaleViewModel()
